import gsap from 'gsap';

import $ from '../core/Dom';
import Viewport from '../core/Viewport';

export default (el, props) => {
    const HAS_TOGGLE_BP = 750;

    const $el = $(el);
    const $menu = $el.find('#menu');
    const $menuBtn = $el.find('[data-menu-toggle-btn]');

    let isOpenMenu = false;
    let toggleTimeline = null;

    let isHidden = false;
    let isLocked = false;
    let hiddenThreshold = 30;
    let currentScrollTop = Viewport.scrollTop;

    const isSmall = bp => (bp || Viewport.breakpoint.size) < 750;

    const init = () => {
        $menuBtn
            .attr({
                tabIndex: '0',
                role: 'button',
                'aria-expanded': 'false'
            })
            .on('keydown', e => {
                const key = e.key || e.keyCode || e.which || null;
                if (['Enter', 13].indexOf(key) > -1) {
                    e.preventDefault();
                    toggleMenu();
                }
            })
            .on('click', e => {
                e.preventDefault();
                toggleMenu();
            })
            .get(0)
            .removeAttribute('href');

        const firstSpan = $menuBtn.find('span').get(0);
        const secondSpan = $menuBtn.find('span').get(1);
        const thirdSpan = $menuBtn.find('span').get(2);

        toggleTimeline = gsap.timeline({ paused: true });
        
        toggleTimeline.to(firstSpan, {
            top: 7,
            ease: 'sine.in',
            duration: 0.2
        }, 0);
        
        toggleTimeline.to(thirdSpan, {
            bottom: 7,
            ease: 'sine.in',
            duration: 0.2
        }, 0);
        
        toggleTimeline.set(secondSpan, { opacity: 0 }, 0.2);
        
        toggleTimeline.to(firstSpan, {
            rotate: 45,
            ease: 'quint.out',
            duration: 0.4
        }, 0.2);
        
        toggleTimeline.to(thirdSpan, {
            rotate: -45,
            ease: 'quint.out',
            duration: 0.4
        }, 0.2);

        $('body')
            .on('keyup', onBodyKeyUp);

        Viewport.on('breakpoint', onBreakpoint);
        Viewport.on('resize', onResize);
        Viewport.on('scroll', onScroll);

        $el.on('focusin', 'a,button', onInnerFocus);

        onResize();

        onBreakpoint();
    };

    const destroy = () => {
        $('body')
            .off('keyup', onBodyKeyUp);
        Viewport.off('breakpoint', onBreakpoint);
        $menuBtn.off('click');
        $menuBtn.off('keydown');

        if (toggleTimeline) {
            toggleTimeline.destroy();
        }
    };

    const onBreakpoint = () => {
        if (Viewport.breakpoint.size >= HAS_TOGGLE_BP) {
            gsap.set($menu.get(0), {
                y: 0,
                opacity: 1,
                display: ''
            });

            if (isOpenMenu) {
                toggleTimeline.reverse();
                isOpenMenu = false;
            }
        }
        if (!isOpenMenu && Viewport.breakpoint.size < HAS_TOGGLE_BP) {
            gsap.set($menu.get(0), {
                y: 40,
                opacity: 0
            });
        }
    };

    const toggleMenu = () => {
        if (isOpenMenu) {
            gsap.to($menu.get(0), {
                opacity: 0,
                y: 40,
                ease: 'sine.in',
                duration: 0.15,
                onComplete: () => {
                    $menu.css({ display: '' });
                }
            });

            toggleTimeline.reverse();
        } else {
            $menu.css({ display: 'block' });

            gsap.to($menu.get(0), {
                opacity: 1,
                y: 0,
                ease: 'quint.out',
                duration: 0.6
            });

            toggleTimeline.play();
        }

        isOpenMenu = !isOpenMenu;
        $menuBtn.attr('aria-expanded', isOpenMenu ? 'true' : 'false');
    };

    const onBodyKeyUp = e => {
        if (isOpenMenu === false) {
            return;
        }
        const key = e.key || e.keyCode || e.which || null;
        if (['Escape', 27].indexOf(key) > -1) {
            toggleMenu();
        }
    };

    /**
     * Scroll behaviour
     */
    const hide = force => {
        if (isHidden) {
            return;
        }
        isHidden = true;

        if (force) {
            $el.addClass('is-forced');

            setTimeout(() => {
                $el.removeClass('is-forced');
            }, 600);
        }

        $el.addClass('is-hidden');
    };

    const show = () => {
        if (!isHidden) {
            return;
        }
        isHidden = false;
        $el.removeClass('is-hidden');
    };

    const lock = () => {
        if (isLocked) {
            return;
        }
        isLocked = true;
        $el.addClass('is-locked');
    };

    const unlock = () => {
        if (!isLocked) {
            return;
        }
        isLocked = false;
        $el.removeClass('is-locked');
    };

    const onScroll = () => {
        const { scrollTop } = Viewport;
        if (Math.abs(scrollTop - currentScrollTop) < 5) {
            return;
        }

        if (scrollTop < hiddenThreshold) {
            show();
            $el.removeClass('is-collapsed');
        } else {
            const direction = scrollTop > currentScrollTop ? 'down' : 'up';
            if (direction === 'down') {
                hide();
            } else {
                show();
            }
            
            $el.addClass('is-collapsed');
        }

        currentScrollTop = scrollTop;
    };

    const onInnerFocus = () => {
        show();
    };

    const getThreshold = () => {
        return isSmall(Viewport.breakpoint) ? 70 : 200; // same for now?
    };

    const onResize = () => {
        hiddenThreshold = getThreshold();

        if (!isHidden) {
            return;
        }

        requestAnimationFrame(() => {
            const { scrollTop } = Viewport;
            if (scrollTop <= hiddenThreshold) {
                show();
            }
        });
    };

    return {
        init,
        destroy
    };
};
