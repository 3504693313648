import gsap from 'gsap';

import $ from '../core/Dom';

export default (el, props) => {
    const $el = $(el);
    const { announceText } = props;
    
    const $selectTierButtonToggle = $el.find('[data-select-tier-toggle]');
    const $selectTierNav = $el.find('[data-select-tier-nav]');
    const $selectTierNavButtons = $el.find('[data-select-tier-button]');
    
    const $selectedTierTitle = $el.find('[data-selected-tier-title]');
    const $selectedTierPrice = $el.find('[data-selected-tier-price]');
    const $selectedTierButton = $el.find('[data-selected-tier-button]');
    
    const $announcer = $el.find('[data-announcer]');
    
    let selectTierOpen = false;

    const init = () => {
        gsap.set($selectTierNav.get(0), { opacity: 0, y: 40 });
        $selectTierButtonToggle.on('click', toggleTierSelector);
        $selectTierNavButtons.on('click', selectTier);
        $el.on('click', '[data-more-information-button]', onInformationButtonClick);
    };

    const destroy = () => {
        
    };
    
    const toggleTierSelector = e => {
        e.preventDefault();
        
        if (selectTierOpen) {
            gsap.to($selectTierNav.get(0), {
                opacity: 0,
                y: 40,
                ease: 'sine.in',
                duration: 0.15, 
                onComplete: () => {
                    $selectTierNav.css({ display: 'none' });
                }
            });
        } else {
            $selectTierNav.css({ display: 'block' });
            
            gsap.to($selectTierNav.get(0), {
                opacity: 1,
                y: 0,
                ease: 'quint.out',
                duration: 0.6
            });
        }
        
        selectTierOpen = !selectTierOpen;
        $selectTierButtonToggle.attr('aria-expanded', selectTierOpen ? 'true' : 'false');
    };
    
    const selectTier = e => {
        e.preventDefault();
        const $triggerTarget = $(e.triggerTarget);
        const data = $triggerTarget.data('select-tier-button');
        console.log(data);
        
        $selectTierNavButtons.attr('aria-current', 'false');
        $triggerTarget.attr('aria-current', 'true');
        
        $selectedTierTitle.text(data.title);
        $selectedTierPrice.text(data.price);
        $selectedTierButton.text(data.buttonLabel).attr('href', data.buttonUrl);
        
        $el.find('[data-tier-column]').addClass('max-l:hidden');
        $el.find('[data-tier-column="'+data.id+'"]').removeClass('max-l:hidden');
        
        toggleTierSelector(e);
        
        $announcer.text(announceText + ' "' + data.title + '"');
    };
    
    const onInformationButtonClick = e => {
        /*
        e.preventDefault();
        const $triggerTarget = $(e.triggerTarget);
        
        if ($triggerTarget.attr('aria-expanded') === 'true') {
            $triggerTarget.attr('aria-expanded', 'false');
        } else {
            $triggerTarget.attr('aria-expanded', 'true');
        }
        *
         */
    };

    return {
        init,
        destroy
    };
};
