import gsap from 'gsap';
import $ from '../core/Dom';
import * as Events from '../lib/events';
import Dispatch from '../core/Dispatch';

const VIDEO_TIMEOUT = 1000;

export default (el, { sources, useObserver = true, videoId = 'default' }) => {
    const $el = $(el);
    const video = $el.find('[data-video]').get(0);
    const image = $el.find('img[hidden],picture[hidden]').get(0);

    let isPlaying = false;

    let observer;
    let timer;

    const show = () => {
        if (gsap) {
            gsap.to(video, {
                opacity: 1,
                duration: 0.2
            });
        } else {
            $(video).css({ opacity: 1 });
        }
        
        Dispatch.emit(Events.VIDEO_LOADED, { videoId });
    };

    const clearTimer = () => {
        if (!timer) {
            return;
        }
        clearTimeout(timer);
        timer = null;
    };

    const cantPlay = () => {
        clearTimer();
        image.hidden = false;
    };

    const onTimeout = () => {
        clearTimer();
        image.hidden = false;
    };

    const onPlaying = () => {
        if (isPlaying) {
            return;
        }

        isPlaying = true;
        clearTimer();
        show();
    };

    const setTimer = () => {
        clearTimer();
        timer = setTimeout(onTimeout, VIDEO_TIMEOUT);
    };

    const onTimeUpdate = e => {
        const { currentTime } = e.target;

        if (currentTime && currentTime > 0.001) {
            onPlaying();
        }
    };

    const onLoadStart = e => {
        if (!timer) {
            return;
        }
        setTimer();
    };

    const playAndCatch = () => {
        video.addEventListener('loadstart', onLoadStart);
        video.addEventListener('loadedmetadata', onLoadStart);
        video.addEventListener('loadeddata', onLoadStart);
        video.addEventListener('canplay', onLoadStart);
        video.addEventListener('timeupdate', onTimeUpdate);

        try {
            const promise = video.play();
            if (promise !== undefined) {
                promise
                    .then(clearTimer)
                    .catch(e => {
                        if (e.name === 'NotAllowedError' || e.name === 'NotSupportedError') {
                            cantPlay();
                        }
                    });
            }
        } catch (error) {
            console.error(error);
            cantPlay();
        }
    };

    const swapSource = ({ src }) => {
        if ($(video)
            .attr('src') === src) {
            return;
        }
        $(video)
            .attr('src', src);
        playAndCatch();
    };

    const bootVideo = () => {
        if (sources.length > 1) {
            sources.forEach(source => {
                const mq = window.matchMedia(source.media);
                const onChange = e => {
                    if (e.matches) {
                        swapSource(source);
                    }
                };
                try {
                    mq.addEventListener('change', onChange);
                } catch (error) {
                    mq.addListener(onChange);
                }
                // Sets initial breakpoint
                if (mq.matches) {
                    swapSource(source);
                }
            });
        } else {
            swapSource(sources[0]);
        }

        setTimer();

    };

    const init = () => {
        if (!video.canPlayType || !video.canPlayType('video/mp4')) {
            cantPlay();
            return;
        }

        if (useObserver) {
            observer = new IntersectionObserver(([{ isIntersecting }]) => {
                if (!isIntersecting) {
                    return;
                }
                bootVideo();
                observer.disconnect();
                observer = null;
            });
            observer.observe(el);
        } else {
            bootVideo();
        }
    };
    
    const destroy = () => {
        clearTimer();

        if (observer) {
            observer.disconnect();
            observer = null;
        }

        video.removeEventListener('loadstart', onLoadStart);
        video.removeEventListener('loadedmetadata', onLoadStart);
        video.removeEventListener('loadeddata', onLoadStart);
        video.removeEventListener('canplay', onLoadStart);
        video.removeEventListener('timeupdate', onTimeUpdate);
    };

    return {
        init,
        destroy
    };

};
