import gsap from 'gsap';
import $ from '../core/Dom';

const $body = $('body');
const $videoOverlay = $body.find('[data-video-overlay]');
const $embedWrapper = $body.find('[data-video-overlay-embed]');
const $closeBtn = $body.find('[data-video-overlay-close-btn]');

let isOpen = false;

const open = embed => {
    $videoOverlay.css('display', null);
    $embedWrapper.append(embed);

    gsap.set($videoOverlay.get(0), { opacity: 0 });
    gsap.to($videoOverlay.get(0), {
        opacity: 1,
        duration: 0.4,
        ease: 'sine.out'
    });

    isOpen = true;
};

const close = () => {
    gsap.to($videoOverlay.get(0), {
        opacity: 0,
        duration: 0.2,
        ease: 'sine.in',
        onComplete: () => {
            $videoOverlay.css('display', 'none');
            $videoOverlay.css('opacity', '');
            $embedWrapper.empty();
        }
    });

    isOpen = true;
};

const onBodyKeyUp = e => {
    if (!isOpen) {
        return;
    }
    const key = e.key || e.keyCode || e.which || null;
    if (['Escape', 27].indexOf(key) > -1) {
        close();
    }
};

const init = () => {
    $body.on('keyup', onBodyKeyUp);
    $closeBtn.on('click', close);

    $videoOverlay.on('click', e => {
        if (e.target === $videoOverlay.get(0)) {
            close();
        }
    });

};

export default {
    init,
    open,
    close
};
