import gsap from 'gsap';

import $ from '../core/Dom';
import { reduceMotion } from '../lib/helpers';


export default (el, props) => {
    const $el = $(el);
    const { initDelay } = props;
    
    const $messagesWrapper = $el.find('[data-messages]');
    const $messages = $el.find('[data-message]');
    
    let messagesTimeline = null;
    let moduleObserver = null;

    const init = () => {
        if (reduceMotion()) {
            gsap.set(el, { display: 'block' });
            return;
        }
        
        gsap.set(el, { display: 'block', opacity: 0, y: 20 });
        gsap.set($messages.nodes, { position: 'absolute', left: 0, opacity: 0 });
        
        messagesTimeline = gsap.timeline({ paused: true })
        
        let nextY = 0;
        let nextInteraction = 0;
        let currentHeight = 0;
        
        $messages.each(node => {
            const $node = $(node);
            const height = $node.height();
            
            nextInteraction += parseFloat($node.data('message-delay'));
            
            gsap.set(node, { y: nextY });
            nextY += height;
            
            messagesTimeline.to($messagesWrapper.get(0), { duration: 0.6, height: currentHeight + height, ease: 'back.out' }, nextInteraction);
            messagesTimeline.to(node, { duration: 0.3, opacity: 1 }, nextInteraction + 0.4);
            
            currentHeight += height;
        });
        
        
        moduleObserver = new IntersectionObserver(([{ isIntersecting }]) => {
            if (!isIntersecting) {
                return;
            }
            
            setTimeout(() => {
                start();
            }, parseFloat(initDelay)*1000);

            moduleObserver.disconnect();
            moduleObserver = null;
        });

        moduleObserver.observe(el);
    };

    const destroy = () => {

    };
    
    const start = () => {
        gsap.to(el, { duration: 0.3, opacity: 1, ease: 'sine.out' });
        gsap.to(el, { duration: 0.6, y: 0, ease: 'back.out' });
        
        messagesTimeline.play();
    };

    return {
        init,
        destroy
    };
};
