import gsap from 'gsap';

import $ from '../core/Dom';

export default (el, props) => {
    const $el = $(el);
    
    const $logoSymbol = $el.find('[data-logo-symbol]');
    const $logoText = $el.find('[data-logo-text]');
    const $logoPunctation = $el.find('[data-logo-punctation]');

    const init = () => {

    };

    const destroy = () => {

    };

    return {
        init,
        destroy
    };
};
