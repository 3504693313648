import $ from './core/Dom';
import Viewport from './core/Viewport';
import Components from './core/Components';
import lazySizes from './lib/lazysizes';
import VideoOverlay from './lib/VideoOverlay';
import PageTransition from './lib/PageTransition';
import Animator from './lib/Animator';

const init = () => {
    Viewport.init();
    Components.init();
    Viewport.initTabbing();
    lazySizes.init();
    VideoOverlay.init();
    PageTransition.init();
    Animator.init();

    $('body').on('click', '[data-video-play-btn]', e => {
        const $target = $(e.triggerTarget);
        const embed = $target.attr('data-embed-code') || '';

        if (embed !== '') {
            e.preventDefault();
            VideoOverlay.open(embed);
        }
    });
};

require('doc-ready')(init);
