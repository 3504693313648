import $ from '../core/Dom';
import * as Events from '../lib/events';
import Dispatch from '../core/Dispatch';
import gsap from 'gsap';

export default (el, props) => {
    const $el = $(el);
    const { videoId } = props;

    
    const init = () => {
        Dispatch.on(Events.VIDEO_LOADED, onVideoLoaded);
    };

    const destroy = () => {
        Dispatch.off(Events.VIDEO_LOADED, onVideoLoaded);
    };
    
    const onVideoLoaded = (key,data) => {
        console.log(data);
        const loadedId = data.videoId || 'default';
        
        if (loadedId === videoId) {
            Dispatch.off(Events.VIDEO_LOADED, onVideoLoaded);
            gsap.to(el, { opacity: 1, duration: 0.4, delay: 0.3, ease: 'sine.inOut' });
        }
    };

    return {
        init,
        destroy
    };
};
