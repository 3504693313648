import gsap from 'gsap';
import ScrollTrigger from 'gsap/ScrollTrigger';
import $ from '../core/Dom';
import Viewport from '../core/Viewport';
import { reduceMotion } from '../lib/helpers';

gsap.registerPlugin(ScrollTrigger);

export default (el, props) => {
    const STICKY_BP_SIZE = 980;
        
    const $el = $(el);
    const $textElements = $el.find('[data-slide-text-element]');
    const $scrollPanels = $el.find('[data-scroll-panel]');

    let moduleObserver = null;
    let activeImage = 1;
    let activeText = 1;

    const init = () => {
        moduleObserver = new IntersectionObserver(([{ isIntersecting }]) => {
            if (!isIntersecting) {
                return;
            }

            $el.find('img.lazyload').addClass('lazypreload');

            moduleObserver.disconnect();
            moduleObserver = null;
        });

        moduleObserver.observe(el);

        if (Viewport.breakpoint.size >= STICKY_BP_SIZE) {
            initSticky();
        }
        
        Viewport.on('resize', onResize);
        Viewport.on('breakpoint', onBreakpoint);
    };

    const destroy = () => {
        if (moduleObserver !== null) {
            moduleObserver.disconnect();
            moduleObserver = null;
        }
        Viewport.off('resize', onResize);
    };
    
    const onResize = () => {
        el.style.setProperty("--element-half-content-height", Math.round($el.find('[data-slide-media-element="1"]').height()/2)+'px');
    };
    
    const onBreakpoint = (e) => {
        console.log(e);
        const oldSize = e.detail.old.size;
        const newSize = e.detail.current.size;
        
        if (oldSize < STICKY_BP_SIZE && newSize >= STICKY_BP_SIZE) {
            initSticky();
        } else if (oldSize >= STICKY_BP_SIZE && newSize < STICKY_BP_SIZE) {
            destroySticky();
        } 
    };
    
    const destroySticky = () => {
        gsap.set($textElements.nodes, { y: 0, opacity: 1 });
        ScrollTrigger.killAll();
    };
    
    const initSticky = () => {
        gsap.set($textElements.nodes.slice(1), { y: 50, opacity: 0 });
        
        $el.find('[data-slide-text-element="1"]').css({ zIndex: 5 });        
        
        onResize();
        
        $scrollPanels.each(node => {
            ScrollTrigger.create({
                trigger: node,
                start: 'top 50%',
                end: 'bottom 50%',
                //markers: true,
                onToggle: (self) => {
                    if (self.isActive) {
                        const index = parseInt(self.trigger.dataset.scrollPanel || 0);
                        activateImage(index);
                        activateText(index);
                    }
                }
            });
        });
    };

    const activateImage = num => {
        if (activeImage !== num) {
            const $currentImage = $el.find('[data-slide-media-element="' + activeImage + '"]');
            const $nextImage = $el.find('[data-slide-media-element="' + num + '"]');

            gsap.killTweensOf($currentImage.nodes);
            gsap.killTweensOf($nextImage.nodes);

            $currentImage.css({ zIndex: 4 });
            gsap.to($currentImage.nodes, {
                delay: 0.4,
                duration: 0.2,
                opacity: 0,
                onComplete: () => {
                    $currentImage.css({ zIndex: null });
                }
            });

            $nextImage.css({ zIndex: 5 });
            gsap.to($nextImage.nodes, {
                duration: 0.5,
                opacity: 1
            });

            activeImage = num;
        }
    };
    
    const activateText = num => {
        if (activeText !== num) {
            const $currentText = $el.find('[data-slide-text-element="' + activeText + '"]');
            const $nextText = $el.find('[data-slide-text-element="' + num + '"]');

            
            gsap.killTweensOf($currentText.nodes);
            gsap.killTweensOf($nextText.nodes);

            $currentText.css({ zIndex: 4 });
            gsap.to($currentText.nodes, {
                delay: 0,
                duration: 0.2,
                y: num > activeText ? -20 : 20,
                opacity: 0,
                onComplete: () => {
                    $currentText.css({ zIndex: null });
                }
            });

            $nextText.css({ zIndex: 5 });
            gsap.set($nextText.nodes, { y: num > activeText ? 50 : -50, opacity: 0 })
            gsap.to($nextText.nodes, {
                duration: 1,
                delay: 0.4,
                ease: 'quart.out', 
                y: 0, 
                opacity: 1 
            });

            activeText = num;
        }
    };    

    return {
        init,
        destroy
    };

};
