import $ from '../core/Dom';
import Viewport from '../core/Viewport';

import { loadFlickity } from '../lib/async-bundles';

export default (el, props) => {
    const SLIDER_SELECTOR = '[data-slides-wrapper]';
    const SLIDE_SELECTOR = '[data-slide-item]';
    const NAV_SELECTOR = '[data-slideshow-nav]';

    const $el = $(el);
    
    const $slider = $el.find(SLIDER_SELECTOR);
    const $slides = $el.find(SLIDE_SELECTOR);
    const $nav = $el.find(NAV_SELECTOR);

    let Flickity;
    let slideshow;
    let lastW = 0;
    let autoplayInterval = 0;
    let moduleObserver = null;

    const init = () => {
        if ($slides.length <= 1) {
            return;
        }
        
        loadFlickity(flickity => {
            Flickity = flickity;
            initFlickity();
            Viewport.on('resize', onResize);
        });
    };

    const destroy = () => {
        if (slideshow) {
            destroyFlickity();
            Viewport.off('resize', onResize);
        }
    };
    
    const initFlickity = () => {
        destroyFlickity();

        $el.addClass('js-has-flickity');
        
        let maxHeight = 0;
        
        $slides.each(item => {
            const $item = $(item);
            $item.css({ display: 'block' });
            const itemHeight = $item.height();
            $item.css({ height: itemHeight });
            maxHeight = Math.max(itemHeight, maxHeight);
        });
        
        $slider.css({
            height: maxHeight
        });
        
        slideshow = new Flickity($slider.get(0), {
            pageDots: false,
            prevNextButtons: false,
            setGallerySize: false,
            resize: false,
            wrapAround: true,
            freeScroll: false,
            contain: true,
            accessibility: true,
            on: {
                change: index => {
                    const $buttons = $nav.find('button');
                    $buttons.removeClass('selected');
                    $($buttons.get(index)).addClass('selected');
                },
                dragStart: index => {
                    cancelAutoPlay();
                }
            }
        });
        
        $nav.on('click', 'button', e => {
            e.preventDefault();
            const $trigger = $(e.triggerTarget);
            const index = $trigger.data('slide-index');
            slideshow.select(index);
            cancelAutoPlay();
        });
        
        moduleObserver = new IntersectionObserver(([{ isIntersecting }]) => {
            if (!isIntersecting) {
                return;
            }
            
            initAutoPlay();
            
            if (moduleObserver) {
                moduleObserver.disconnect();
            }
            moduleObserver = null;
        });

        moduleObserver.observe(el);
    };
    
    const destroyFlickity = () => {
        if (!slideshow) {
            return;
        }
        
        slideshow.destroy();
        slideshow = null;

        $slider.attr('style', '');
        $slides.attr('style', '');
        
        $el.removeClass('js-has-flickity');
    };

    const initAutoPlay = () => {
        clearInterval(autoplayInterval);
        
        autoplayInterval = setInterval(() => {
            if (slideshow) {
                slideshow.next();
            }
        }, 6000);
    };
    
    const cancelAutoPlay = () => {
        clearInterval(autoplayInterval);
        
        if (moduleObserver !== null) {
            moduleObserver.disconnect();
            moduleObserver = null;
        }
    };
    
    const onResize = () => {
        if (Viewport.width !== lastW) {
            initFlickity();
            lastW = Viewport.width;
        }
    };
    
    return {
        init,
        destroy
    };

};
