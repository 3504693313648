import gsap from 'gsap';

import $ from '../core/Dom';
import Viewport from '../core/Viewport';

export default el => {
    const $el = $(el);
    const $lineWrapper = $el.find('[data-logos-line-wrapper]');
    const $line = $el.find('[data-logos-line]');

    let duplicates = [];
    let timeline = null;
    let moduleObserver = null;
    let isPlaying = false;
    let lastWidth = 0;

    const init = () => {
        createSlider();

        moduleObserver = new IntersectionObserver(onModuleObserve, {});
        moduleObserver.observe(el);

        Viewport.on('resize', onResize);
    };

    const destroy = () => {
        destroySlider();
    };

    const createSlider = () => {
        if (duplicates.length > 0) {
            destroySlider();
        }

        const lineWidth = getLineWidth();

        const needDuplicates = Math.ceil((Viewport.width / lineWidth) * 2) - 1;

        for (let i = 0; i < needDuplicates; i++) {
            const $dup = $line.clone();
            $dup.css({ position: 'absolute', left: lineWidth * (i + 1), top: 0 });
            duplicates.push($dup);
            $lineWrapper.append($dup);
        }

        $lineWrapper.css({ opacity: 1 });

        if (timeline) {
            timeline.revert();
        }

        timeline = gsap.timeline({ repeat: -1 });
        timeline.to($lineWrapper.get(0), { x: -lineWidth, duration: lineWidth / 50, ease: 'none' });
        timeline.pause();
    };

    const getLineWidth = () => {
        let totalWidth = 0;

        $line.find('img').each(item => {
            const $item = $(item);
            totalWidth += $item.width();
        });

        return totalWidth;
    };

    const destroySlider = () => {
        timeline.kill();
        timeline = null;

        $lineWrapper.attr('style', '');

        duplicates.forEach(item => {
            $(item).remove();
        });

        duplicates = [];
    };

    const onResize = () => {
        if (Viewport.width === lastWidth) {
            return;
        }
        
        lastWidth = Viewport.width;
        createSlider();
    };

    const onModuleObserve = entries => {
        const intersecting = [];
        
        entries.forEach(entry => {
            const {
                target,
                isIntersecting,
                intersectionRatio
            } = entry;
            if (!isIntersecting || (intersectionRatio < 0)) {
                return;
            }
            intersecting.push({
                target,
                intersectionRatio
            });
        });

        if (timeline) {
            if (isPlaying && intersecting.length === 0) {
                timeline.pause();
                isPlaying = false;
            }
            if (!isPlaying && intersecting.length > 0) {
                timeline.play();
                isPlaying = true;
            }
        }
    };

    return {
        init,
        destroy
    };

};
